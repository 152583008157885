import { merge } from "theme-ui"
// import { tailwind } from "@theme-ui/presets"
// import { theme as caraTheme } from "@lekoarts/gatsby-theme-cara/src/gatsby-plugin-theme-ui/index"
// import {Layout as ThemeLayout} from "@lekoarts/gatsby-theme-cara/src/gatsby-plugin-theme-ui"

const theme = merge({}, {
  colors: {
    background: `#040C15`,
    text: `#FFFFFF`,
    primary: `#00F152`,
    support: `#AEFCE1`,
  },
  breakpoints: [`640px`, `832px`, `1024px`, `1216px`],
})

export default theme