/** @jsx jsx */
import * as React from "react"
import { jsx } from "theme-ui"

const RoadmapGridUnit = ({
  children,
  ...props
}) => {
  
  return (
    <div sx={{
      // display: `grid`,
      // gridAutoFlow: `row`,
      // gridTemplateRows: gridTemplate.rows,
      // gridTemplateColumns: gridTemplate.cols,
      p: {
        margin: `0`,
        // padding: [`1.406rem 1.125rem 1.078rem`, null, null, `1.875rem 2.25rem 1.4375rem`],
      },
      // width: [`63rem`, `74rem`, null, null, `auto`],
      // padding: [`0 1.5rem 0 1.5rem`, null, null, null, `0`]
    }}>
      <p>{children}</p>
    </div>
  )
}

export default RoadmapGridUnit