import React, { useState } from 'react'

const defaultState = {
  opened: false,
  headerTransparent: true,
  headerWidthTo: null,
  hightlights: {},
  items: [
    {
      label: 'Docs',
      href: 'https://docs.platypus.finance/platypus-finance-docs/',
      target: '_self',
      eventProps: {
        event_action: 'Click on {location}',
        event_category: 'Docs Reading',
        event_label: 'Docs',
      },
      viewability: '111',
    },
    {
      label: 'Airdrop',
      href: 'https://airdrop.platypus.finance',
      target: '_self',
      eventProps: {
        event_action: 'Click on {location}',
        event_category: 'Event Airdrop',
        event_label: 'Launch Claim PTP Page',
      },
      viewability: '010',
    },
    {
      label: 'Risk',
      href: '/risk',
      target: '_self',
      eventProps: {
        event_action: 'Click on {location}',
        event_category: 'Page Reading',
        event_label: 'Risk',
      },
      viewability: '011',
    },
    {
      label: 'Dashboard',
      href: 'https://dashboard.platypus.finance/',
      target: '_blank',
      eventProps: {
        event_action: 'Click on {location}',
        event_category: 'Direct to Dashboard',
        event_label: 'Dashboard',
      },
      viewability: '011',
    },
    {
      label: 'Governance',
      viewability: '111',
      items: [
        {
          label: 'Governance Forum',
          href: 'https://gov.platypus.finance/',
          target: '_blank',
          eventProps: {
            event_action: 'Click on {location}',
            event_category: 'Governance Forum',
            event_label: 'Governance Forum',
          },
          viewability: '111',
        },
        {
          label: 'Snapshot',
          href: 'https://snapshot.org/#/platypusdefi.eth',
          target: '_blank',
          eventProps: {
            event_action: 'Click on {location}',
            event_category: 'Snapshot',
            event_label: 'Snapshot',
          },
          viewability: '111',
        },
      ],
    },
    {
      label: 'Audits',
      viewability: '111',
      items: [
        {
          label: 'Hacken',
          href: 'https://hacken.io/audits/#platypus_finance',
          target: '_blank',
          eventProps: {
            event_action: 'Click on {location}',
            event_category: 'Audit Report Reading',
            event_label: 'Hacken',
          },
        },
        {
          label: 'Omniscia - Core Implementation',
          href: 'https://omniscia.io/platypus-finance-core-implementation/',
          target: '_blank',
          eventProps: {
            event_action: 'Click on {location}',
            event_category: 'Audit Report Reading',
            event_label: 'Omniscia',
          },
        },
        {
          label: 'Omniscia - Governance Staking Implementation',
          href: 'https://omniscia.io/platypus-finance-governance-staking/',
          target: '_blank',
          eventProps: {
            event_action: 'Click on {location}',
            event_category: 'Audit Report Reading',
            event_label: 'Omniscia',
          },
        },
        {
          label: 'Supremacy',
          href: 'https://github.com/SupremacyTeam/publications/blob/main/Supremacy-Audit-Report-PlatypusFinance-v1.0.pdf',
          target: '_blank',
          eventProps: {
            event_action: 'Click on {location}',
            event_category: 'Audit Report Reading',
            event_label: 'Supremacy',
          },
        },
      ],
    },
    {
      label: 'Research',
      viewability: '111',
      items: [
        {
          label: 'Liquidity Mining Paper',
          href: 'https://cdn.platypus.finance/Platypus_Liquidity_Mining_Paper.pdf',
          target: '_blank',
          eventProps: {
            event_action: 'Click on {location}',
            event_category: 'Research Reading',
            event_label: 'Liquidity Mining Paper',
          },
          viewability: '111',
        },
        {
          label: 'AMM Yellow Paper',
          href: 'https://cdn.platypus.finance/Platypus_AMM_Yellow_Paper.pdf',
          target: '_blank',
          eventProps: {
            event_action: 'Click on {location}',
            event_category: 'Research Reading',
            event_label: 'AMM Yellow Paper',
          },
          viewability: '111',
        },
        {
          label: 'Stablecoin Yellow Paper',
          href: 'https://cdn.platypus.finance/Platypus_Stablecoin_Yellow_Paper.pdf',
          target: '_blank',
          eventProps: {
            event_action: 'Click on {location}',
            event_category: 'Research Reading',
            event_label: 'Stablecoin Yellow Paper',
          },
          viewability: '111',
        },
      ],
    },
  ],
  isLoading: false,
  loadingMessage: 'Loading...',
  recalculationOnResize: false,
  instructions: {},
  // toggleMenu: () => {},
  // toggleHeaderTransparency: () => {},
  // setHeaderTransparency: () => {},
  // resetMenuItems: () => {},
  // addMenuItem: () => {},
  // reviseMenuItem: () => {},
  // setHeaderWidthTo: () => {},
  // defineViewability: () => {}
}
const AppContext = React.createContext(defaultState)

class AppProvider extends React.Component {
  state = {
    opened: false,
    headerTransparent: true,
    headerWidthTo: null,
    items: [],
    hightlights: {},
    isLoading: false,
    loadingMessage: 'Loading...',
    recalculationOnResize: false,
    instructions: {},
  }

  toggleMenu = () => {
    let opened = !this.state.opened
    localStorage.setItem('opened', JSON.stringify(opened))
    this.setState({ opened })
  }

  toggleHeaderTransparency = () => {
    let transparency = !this.state.headerTransparent
    localStorage.setItem('headerTransparent', JSON.stringify(transparency))
    this.setState({ headerTransparent: transparency })
  }

  setHeaderTransparency = (_to) => {
    let transparency = this.state.headerTransparent
    if (transparency !== _to) {
      localStorage.setItem('headerTransparent', JSON.stringify(_to))
      this.setState({ headerTransparent: _to })
    }
  }

  resetMenuItems = () => {
    let items = defaultState.items
    this.setState({ items })
  }

  addMenuItem = (insert, at) => {
    const isArrayInsertion = Array.isArray(insert)
    let items = isArrayInsertion
      ? at !== undefined && at > -1
        ? [
            ...defaultState.items.filter((item, index) => index < at),
            ...insert,
            ...defaultState.items.filter((item, index) => index >= at),
          ]
        : [...defaultState.items, ...insert]
      : at !== undefined && at > -1
      ? [
          ...defaultState.items.filter((item, index) => index < at),
          insert,
          ...defaultState.items.filter((item, index) => index >= at),
        ]
      : [...defaultState.items, insert]
    this.setState({ items })
  }

  reviseMenuItem = (itemPropsRev, at) => {
    let itemsRev = this.state.items.map((itm, index) => {
      return index === at
        ? {
            ...itm,
            ...itemPropsRev,
          }
        : itm
    })
    this.setState({ items: itemsRev })
  }

  setHeaderWidthTo = (_width) => {
    this.setState({ headerWidth: _width })
  }

  defineViewability = (config, displayArea) => {
    /*
      0 = header
      1 = footer
      2 = hamburger menu for mobile devices
    */
    return Boolean(
      parseInt(
        config.split('')[displayArea] !== undefined
          ? config.split('')[displayArea]
          : '0',
      ),
    )
  }

  showLoading = () => {
    this.setState({ isLoading: true })
  }

  hideLoading = () => {
    this.setState({ isLoading: false })
  }

  setLoadingMessage = (message) => {
    this.setState({ loadingMessage: message })
  }

  resetLoadingMessage = () => {
    this.setState({ loadingMessage: defaultState.loadingMessage })
  }

  setRecalculationOnResize = (setting) => {
    // if (this.state.recalculationOnResize !== _config) {
    console.log('&&***$$$$ ^ A ^ $$$$***&& : RQ => ', setting)
    this.setState({ recalculationOnResize: setting })
    // }
  }

  setSectionHighlights = ({ key, value }) => {
    let valCollector = {}
    valCollector[key] = value
    this.setState({
      highlights: valCollector,
    })
  }

  setInstructions = ({ key, value }) => {
    let valCollector = {}
    valCollector[key] = value
    this.setState({
      instructions: valCollector,
    })
  }

  resetInstructions = () => {
    this.setState({ instructions: defaultState.instructions })
  }

  getState = (name) => {
    console.log(
      `&&***$$$$ ^ A ^ $$$$***&& : GET : ${name} => `,
      this.state[name],
    )
    return this.state[name]
  }

  componentDidMount() {
    // Getting dark mode value from localStorage!
    // const lsMenuOpened = JSON.parse(localStorage.getItem("opened"))
    // if (lsMenuOpened) {
    //   this.setState({ opened: lsMenuOpened })
    // }
    console.log('&&***$$$$ ^ A ^ $$$$***&& : DM')
    this.setState({
      opened: false,
      headerTransparent: true,
      headerWidth: null,
      items: defaultState.items,
      highlights: defaultState.highlights,
      isLoading: defaultState.isLoading,
      loadingMessage: defaultState.loadingMessage,
      recalculationOnResize: defaultState.recalculationOnResize,
      instructions: defaultState.instructions,
    })
    // if (this.state.items === undefined || this.state.items.length === 0) {
    //   this.resetMenuItems()
    // }
  }

  render() {
    const { children } = this.props
    const {
      opened,
      items,
      highlights,
      headerTransparent,
      headerWidth,
      isLoading,
      loadingMessage,
      recalculationOnResize,
      instructions,
    } = this.state
    // console.log('$$%%$$%%$$')
    // console.log('$$$%%%%$$$')
    // console.log('$$$$%%$$$$')
    // console.log('$$ data $$')
    // console.log(data)
    return (
      <AppContext.Provider
        value={{
          opened,
          items,
          highlights,
          headerTransparent,
          headerWidth,
          isLoading,
          loadingMessage,
          recalculationOnResize,
          instructions,
          toggleMenu: this.toggleMenu,
          toggleHeaderTransparency: this.toggleHeaderTransparency,
          setHeaderTransparency: this.setHeaderTransparency,
          resetMenuItems: this.resetMenuItems,
          addMenuItem: this.addMenuItem,
          reviseMenuItem: this.reviseMenuItem,
          setHeaderWidthTo: this.setHeaderWidthTo,
          setSectionHighlights: this.setSectionHighlights,
          setInstructions: this.setInstructions,
          resetInstructions: this.resetInstructions,
          defineViewability: this.defineViewability,
          showLoading: this.showLoading,
          hideLoading: this.hideLoading,
          setLoadingMessage: this.setLoadingMessage,
          resetLoadingMessage: this.resetLoadingMessage,
          setRecalculationOnResize: this.setRecalculationOnResize,
          getState: this.getState,
        }}
      >
        {children}
      </AppContext.Provider>
    )
  }
}

export default AppContext
export { AppProvider }
